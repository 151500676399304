import SubMenu from "components/layout/SubMenu.js";
import SubMenuCustom from "components/layout/SubMenu.js/SubMenuCustom";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, redirect } from "react-router-dom";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import "./PostWork.css";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  IconButton,
  Container,
  FormControl,
  FormGroup,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Input,
} from "@mui/material";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useForm, Controller } from "react-hook-form";
import InputDisable from "components/input/inputDisable";
import ButtonSwitch from "components/input/buttonSwitch";
import SelectLocation from "components/work/selectLocation";
import Line from "components/layout/line";
import SelectTruck from "components/work/selectTruck";
import SelectProduct from "components/work/selectProduct";
import SelectService from "components/work/selectService";
import SelectDateTime from "components/work/selectDateTime";
import { LongdoMap, map, longdo } from "components/map/LongdoMap";
import SelectBilling from "components/work/selectBilling";
import SelectRemark from "components/work/selectRemark";
import SelectPostName from "components/work/selectPostName";
import SelectFavCarrier from "components/work/selectFavCarrier";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InputNormal from "components/input/inputNormal";
import API from "../../utils/api/work/work";
import Swal from "sweetalert2";
import InputSelect from "components/input/inputSelect";
import HelperMap from "utils/helperMap";
import { getTruckType } from "utils/api/masterData/truckType";
import masterProduct from "utils/api/masterData/productType";
import masterProvice from "utils/api/masterData/provice";
import ModalWork from "components/work/modalWork";
import helper from "utils/helper";
import SelectCarrier from "components/work/selectCarrier";
import WorkStatus from "components/work/workStatus";
import SelectPrice from "components/work/selectPrice";
import EditButton from "components/input/editButton";
import ModalUpdateStatus from "components/work/modalUpdateStatus";
import { getProfileReceiptName } from "utils/api/profile/profileReceiptName";
import Loading from "components/loading/Loading";
import LoadingBackground from "components/loading/LoadingBackground";
import MapWithCall from "components/map/MapWithCall";
import { io } from "socket.io-client";
import PaymentStatus from "components/work/paymentStatus";
import CopyToClipboard from "react-copy-to-clipboard";
import RateUser from "components/work/rateUser";
import AdminNote from "components/work/adminNote";
import Master from "utils/master";
import InputUpload from "components/input/inputUpload";
import AuthContext from "components/context/AuthContext";

const api = API.create();
const menus = [
  {
    label: "รายละเอียดงาน",
    path: "/work-detail/",
    active: true,
  },
];

const noteTypeOptions = [
  { value: "0", item: "ลูกค้า (Shipper)" },
  { value: "1", item: "ผู้ขนส่ง (Carrier)" },
  { value: "2", item: "คนขับ (Driver)" },
  { value: "3", item: "ผู้ดูแลระบบ (Admin)" },
];
// const breadcrumbs = [
//   {
//     label: "ข้อมูลงาน",
//     path: null,
//   },
//   {
//     label: "รายการเดียว",
//     path: null,
//   },
// ];

export default function WorkDetails() {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [showStatus, setShowStatus] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalEditDetail, setShowModalEditDetail] = useState(false);
  const [modalShare, setModalShare] = useState(false);

  const [workDetail, setWorkDetail] = useState();
  const [generalPrice, setGeneralPrice] = useState();
  const [onDemandPrice, setOnDemandPrice] = useState();
  const [driverLoc, setDriverLoc] = useState();
  const [adminNote, setAdminNote] = useState([]);
  const [acceptWork, setAcceptWork] = useState(null);
  const [clipboardInfo, setClipboardInfo] = useState(null);
  const [clipboardInfoCarrier, setClipboardInfoCarrier] = useState(null);
  const [clipboardLocation, setClipboardLocation] = useState(null);
  const [editPrice, setEditPrice] = useState(false);
  const [truckType, setTruckType] = useState();
  const { adminAuth } = useContext(AuthContext);
  console.log("adminAuth", adminAuth);

  // เช็คว่ามี role ฝ่ายการเงินและบัญชี หรือ super_admin ไหม
  const fixedRole =
    adminAuth?.admin_role_type === 0 || adminAuth?.admin_role_type === 4;
  let haveFixedRole = false;
  if (fixedRole) {
    haveFixedRole = true;
  }

  const [service, setService] = useState({
    has_document_return: false,
    document_return_code: "",
    document_return_address: "",
    document_return_district: "",
    document_return_subdistrict: "",
    document_return_province: "",
    document_return_postal_code: "",
    document_return_contact_name: "",
    document_return_contact_phone: "",
    has_labor: false,
    labor_load_amount: 0,
    labor_unload_amount: 0,
    has_driver_assist_load_unload: false,
  });
  const [distanceTime, setDistanceTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [province, setProvice] = useState(null);
  const [loadMap, setLoadmap] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [receipt, setReceipt] = useState();
  const [call, setCall] = useState();
  const [markerStatus, setMarkerStatus] = useState();

  const [datetime, setDatetime] = useState({});
  const [deposit, setDeposit] = useState({
    has_deposit: 0,
    deposit_amount: "",
  });
  const [price, setPrice] = useState({
    shipper: {
      shipper_document_return_fee: null, // ค่าเอกสารนำกลับ
      shipper_labor_load_fee: null, // ค่าพนักงานขึ้นสินค้า
      shipper_labor_unload_fee: null, // ค่าพนักงานลงสินค้า
      shipper_add_drop_point_fee: null, // ค่าเพิ่มจุดส่ง
      shipper_transport_fee: null, // ค่าจัดส่ง
      shipper_freight_before_tax: null, // รวมราคาเรียกเก็บลูกค้าก่อนหักภาษี
      shipper_freight_tax: null, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาเรียกเก็บลูกค้าก่อนหักภาษี
      shipper_freight_net: null, // ค่าขนส่งสุทธิที่ต้องรับชำระลูกค้า (กรณีชำระเต็มจำนวน)
      shipper_has_deposit: null, // มีเงินมัดจำ
      shipper_deposit_amount: null, // จำนวนเงินมัดจำ
      commission: null, //ค่านายหน้า
    },
    carrier: {
      carrier_freight: null, // ค่าจ้างผู้ขนส่ง
      carrier_saas_fee: null, // ค่าธรรมเนียมระบบ
      carrier_freight_before_tax: null, // รวมราคาจ่ายผู้ขนส่งก่อนหักภาษี
      carrier_freight_tax: null, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาจ่ายผู้ขนส่งก่อนหักภาษี
      carrier_penalty: null, // ค่าปรับกรณีทำผิดข้อตกลง
      carrier_insurance_per_trip: null, // ค่าประกันภัยสินค้ารายเที่ยว
      carrier_transfer_fee: null, // ค่าธรรมเนียมการโอนเงิน
      carrier_freight_net: null, // ค่าขนส่งสุทธิที่ต้องชำระผู้ขนส่ง (กรณีชำระเต็มจำนวน)
    },
    difference: {
      price_difference: null,
      price_difference_percentage: null,
    },
  });
  const [district, setDistrict] = useState(null);
  const [socketId, setSocketId] = useState("test");
  const [rate, setRate] = useState({
    shipper: {
      shipper_score: 0,
      shipper_comment: "",
    },
    carrier: {
      carrier_score: 0,
      carrier_comment: "",
    },
  });
  const [subDistrict, setSubDistrict] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [location, setLocation] = useState([
    {
      type: 0,
      name: "",
      lat: "",
      long: "",
      address: "",
      district: "",
      subdistrict: "",
      province: "",
      postal_code: "",
      contact_name: "",
      contact_phone: "",
      place_name: "",
      place_type_id: 0,
      remark: "",
      document: "",
      document_file: [],
      product: [
        {
          name: "",
          product_id: "",
          product_name: "",
          product_size: "",
          product_width: "",
          product_length: "",
          product_height: "",
          product_volume: "",
          product_weight: "",
          product_count_type: "",
          product_amount: "",
          product_image: [null, null, null, null, null],
          product_image_per_route: [],
        },
      ],
    },
    {
      type: 1,
      name: "",
      lat: "",
      long: "",
      address: "",
      district: "",
      subdistrict: "",
      province: "",
      postal_code: "",
      contact_name: "",
      contact_phone: "",
      place_name: "",
      place_type_id: 0,
      remark: "",
      document: "",
      document_file: [],
      product: [
        {
          name: "",
          product_id: "",
          product_name: "",
          product_size: "",
          product_width: "",
          product_length: "",
          product_height: "",
          product_volume: "",
          product_weight: "",
          product_count_type: "",
          product_amount: "",
          product_image: [null, null, null, null, null],
          product_image_per_route: [],
        },
      ],
    },
  ]);

  //for edit work at differnt month
  const [adminNoteEdit, setAdminNoteEdit] = useState({
    note_type: "3",
    detail: "",
    image: "",
  });
  const [showAdminNoteEdit, setShowAdminNoteEdit] = useState(false);

  const accept_date = workDetail?.workTrackings?.filter(
    (item) => item.work_status === 3
  );
  let accepted_work = false;
  if (accept_date?.length > 0) {
    accepted_work = true;
  }
  console.log("accept_date", accept_date);
  console.log("accepted_work", accepted_work);
  const actual_date_tracking = accept_date?.sort(
    (a, b) => new Date(b?.actual_date) - new Date(a?.actual_date)
  )[0]?.actual_date;

  const actualDate = new Date(actual_date_tracking);
  const currentDate = new Date();

  console.log("actual_date_tracking", actual_date_tracking);
  const is_different_months =
    actualDate.getMonth() !== currentDate.getMonth() ? true : false; //ต่างเดือนไหม

  const nextMonth = new Date(actualDate);
  nextMonth.setMonth(actualDate.getMonth() + 1, 3); //กำหนดเป็นวันที่ 3 ของเดือนถัดจาก actual_date

  const check_date_no_role = currentDate <= nextMonth; // ไม่่เกินวันที่ 3 ของเดือนถัดจาก actual_date

  console.log("haveFixedRole", haveFixedRole);
  console.log("actualDate", actualDate);
  console.log("nextMonth", nextMonth);
  console.log("is_different_months", is_different_months);
  console.log("check_date_no_role", check_date_no_role);
  // useEffect(() => {
  //   const socket = io(process.env.REACT_APP_API_ENPOINT, {
  //     auth: { Authorization: helper.getCookie("access_token") },
  //   }); // Replace with your backend URL
  //   // Event handler for receiving messages from the server
  //   socket.on("message", (data) => {
  //     console.log("Received message from server:", data);
  //     console.log("socket", socket.id);
  //     setSocketId(socket.id);
  //     // Handle the message as needed in your React component
  //   });
  //   socket.on("driver_update", (data) => {
  //     console.log("driver_update:", data);
  //     // setProgress(data);
  //     //   console.log("socket", socket.id);
  //     // setSocketId(socket.id);
  //     // Handle the message as needed in your React component
  //   });
  //   // Clean up the socket connection when the component unmounts
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);
  const { id } = useParams();
  const navigate = useNavigate();
  const initMap = () => {};
  useEffect(() => {
    if (!truckType) {
      getTruck();
    }
    getProvince();
    getDistrict();
    getSubDistrict();
  }, []);
  useEffect(() => {
    if (loadMap) {
      setMarker();
      if (
        workDetail?.workTrackings.filter(
          (e) => e.work_status === 5 || e.work_status === 6
        ).length > 0
      ) {
        getDriver();
        setInterval(() => {
          getDriver();
        }, 20000);
      }
    }
  }, [loadMap]);
  useEffect(() => {
    let ele = document.getElementById("post-work-box-map");
    // console.log(ele.offsetHeight);
    let height = Math.floor(ele.offsetHeight * 0.95);
    if (showStatus) {
      document.getElementById("work-detail-status").style.height =
        height + "px";
    } else {
      document.getElementById("work-detail-status").style.height = null;
    }
  }, [showStatus]);
  useEffect(() => {
    if (province && district && subDistrict) {
      getWorkDetail();
    }
  }, [province, district, subDistrict]);
  const getTruck = async () => {
    let truck = await getTruckType();
    // console.log(truck);
    setTruckType(truck.data.data.results);
  };
  const getProvince = async () => {
    let pv = await masterProvice.Province();
    let mapPv = pv.data.data.results.map((ele) => {
      return { ...ele, item: ele.name, value: ele.id };
    });
    // console.log(mapPv);
    setProvice(mapPv);
  };
  const getDistrict = async () => {
    let dis = await masterProvice.District();
    let mapDis = dis.data.data.results.map((ele) => {
      return {
        ...ele,
        item: ele.name,
        value: ele.id,
      };
    });
    // console.log(mapDis);
    setDistrict(mapDis);
  };
  const getSubDistrict = async () => {
    let subdis = await masterProvice.SubDistrict();
    let mapDis = subdis.data.data.results.map((ele) => {
      return {
        ...ele,
        item: ele.name,
        value: ele.id,
      };
    });
    // console.log(mapDis);
    setSubDistrict(mapDis);
  };
  useEffect(() => {
    if (workDetail && location) {
      getGeneralPrice();
      getOnDemandPrice();
    }
  }, [workDetail, location, receipt]);
  useEffect(() => {
    if (refresh) {
      setLoading(true);
      getWorkDetail();
    }
  }, [refresh]);
  useEffect(() => {
    //set truck info
    let accept;
    workDetail?.workTrackings?.filter((e) => e.work_status === 4).length > 0
      ? (accept = workDetail?.workTrackings?.filter(
          (e) => e.work_status === 4
        )[0].workTrackingDetail)
      : (accept = null);
    setAcceptWork(accept);
  }, [workDetail?.workTrackings]);

  useEffect(() => {
    console.log("acceptWork", acceptWork);
  }, [acceptWork]);
  useEffect(() => {
    if (workDetail) {
      let pickup;
      workDetail?.workTrackings?.filter((e) => e.work_status === 8).length > 0
        ? (pickup = workDetail?.workTrackings?.filter(
            (e) => e.work_status === 8
          )[0])
        : (pickup = null);

      let send;
      workDetail?.workTrackings?.filter((e) => e.work_status === 10).length > 0
        ? (send = workDetail?.workTrackings?.filter(
            (e) => e.work_status === 10
          )[0])
        : (send = null);

      let origin = location?.filter((ele, index) => ele.type === 0);
      let destination = location?.filter((ele, index) => ele.type === 1);
      let orig_detail = origin?.map((ele, index) => {
        return `\n🔺จุดรับ: ${ele.district} ${ele.province}`;
      });

      let dest_detail = destination?.map((ele, index) => {
        return `\n🔻จุดส่ง${index + 1}: ${ele.district} ${ele.province}`;
      });

      let text =
        "📋เลขที่งาน: " +
        (workDetail?.work_code ?? "") +
        orig_detail[0] +
        dest_detail.map((val) => {
          return val;
        }) +
        "\n🕘สถานะงานล่าสุด: " +
        (workDetail?.workTrackings?.[0]?.workStatus.name ?? "") +
        (workDetail?.workTrackings?.[0]?.workStatus.id === 4 ||
        workDetail?.workTrackings?.[0]?.workStatus.id === 6
          ? "\n📆วันเวลาที่ยืนยันเข้ารับสินค้า: " +
            helper.momentDateTime(acceptWork?.load_date ?? new Date())
          : workDetail?.workTrackings?.[0]?.workStatus.id === 8
          ? "\n📆วันเวลาที่เข้ารับสินค้า: " +
            helper.momentDateTime(pickup?.actual_date ?? new Date())
          : workDetail?.workTrackings?.[0]?.workStatus.id === 10
          ? "\n📆วันเวลาที่ส่งรับสินค้า: " +
            helper.momentDateTime(send?.actual_date ?? new Date())
          : "") +
        "\n👨🏻ชื่อพนักงานขับรถ: " +
        (workDetail?.driver?.firstname ?? "") +
        " " +
        (workDetail?.driver?.lastname ?? "") +
        "\n☎️เบอร์โทรพนักงานขับรถ: " +
        (workDetail?.driver?.phone ?? "") +
        "\n🚛ทะเบียนรถ: " +
        (acceptWork?.truck
          ? acceptWork?.truck?.license_plate_head +
            (acceptWork?.truck?.license_plate_tail &&
            acceptWork?.truck?.license_plate_tail !== ""
              ? " " + acceptWork?.truck?.license_plate_tail
              : "")
          : "") +
        "\n🌐ลิงก์ติดตามสถานะ: " +
        ("https://shipperv3.wemove.co.th/public/tracking/" +
          workDetail?.id_secret);

      let textCarrier =
        "📋เลขที่งาน: " +
        (workDetail?.work_code ?? "") +
        "\n🚚สถานะงานล่าสุด: " +
        (workDetail?.workTrackings?.[0]?.workStatus.name ?? "") +
        orig_detail[0] +
        dest_detail.map((val) => {
          return val;
        }) +
        "\n📅วันรับสินค้า: " +
        helper.momentTwoDate(
          datetime?.date?.startDate ?? new Date(),
          datetime?.date?.endDate ?? new Date(),
          "short",
          false
        ) +
        "\n🕘เวลารับสินค้า: " +
        ((datetime?.time?.time_start ?? "") +
          " - " +
          (datetime?.time?.time_end ?? "")) +
        "\n📦ประเภทสินค้า: " +
        (location[0].product[0].name && location[0].product[0].name !== ""
          ? location[0].product.map((ele, index) => {
              return ele.name;
            })
          : "") +
        "\n🅾️บริการเสริม: " +
        (service.has_document_return ? "ต้องการเอกสารนำกลับ, " : "") +
        (service.has_labor && service.labor_load_amount !== 0
          ? `พนักงานขึ้นสินค้า ${service.labor_load_amount} คน, `
          : "") +
        (service.has_labor && service.labor_unload_amount !== 0
          ? `พนักงานลงสินค้า ${service.labor_unload_amount} คน, `
          : "") +
        "\n💵ค่าจ้าง: " +
        (price?.carrier.carrier_freight_before_tax ?? 0) +
        " บาท" +
        "\n" +
        "\n✅สนใจรับงาน: " +
        ("https://carrierv3.wemove.co.th/open-app/" + workDetail?.id) +
        "\n" +
        "\n📱ติดต่อเจ้าหน้าที่" +
        "\nLine: @wmcarrier หรือ https://lin.ee/sRWQ8jz" +
        "\nโทร: 02-114-7609 หรือ 080-835-7955";

      let textLoc = "";
      // "📍จุดรับ" +
      // "\nชื่อผู้ติดต่อ: " +
      // workDetail.workLocations[0].contact_name +
      // "\nเบอร์โทรผู้ติดต่อ: " +
      // workDetail.workLocations[0].contact_phone +
      // "\nชื่อสถานที่: " +
      // workDetail.workLocations[0].location_name +
      // "\nประเภทสถานที่: " +
      // Master.place_type.filter(
      //   (e) => e.value === workDetail.workLocations[0].location_type
      // )[0].value +
      // "\nหมายเหตุ: " +
      // workDetail.workLocations[0].remark +
      // "\nพิกัด: " +
      // "https://maps.google.com/?q=" +
      // workDetail.workLocations[0].lat +
      // "," +
      // workDetail.workLocations[0].long
      workDetail.workLocations.forEach((e, i) => {
        console.log("workDetail.workLocations", e);
        if (i === 0) {
          textLoc +=
            "📍จุดรับ" +
            "\nชื่อผู้ติดต่อ: " +
            e.contact_name +
            "\nเบอร์โทรผู้ติดต่อ: " +
            e.contact_phone +
            "\nชื่อสถานที่: " +
            e.location_name +
            "\nประเภทสถานที่: " +
            Master.place_type.filter((el) => el.value === e.location_type)[0]
              .item +
            "\nหมายเหตุ: " +
            e.remark +
            "\nพิกัด: " +
            "https://maps.google.com/?q=" +
            e.lat +
            "," +
            e.long;
        } else {
          textLoc +=
            "\n\n📍จุดส่ง" +
            i +
            "\nชื่อผู้ติดต่อ: " +
            e.contact_name +
            "\nเบอร์โทรผู้ติดต่อ: " +
            e.contact_phone +
            "\nชื่อสถานที่: " +
            e.location_name +
            "\nประเภทสถานที่: " +
            Master.place_type.filter((el) => el.value === e.location_type)[0]
              .item +
            "\nหมายเหตุ: " +
            e.remark +
            "\nพิกัด: " +
            "https://maps.google.com/?q=" +
            e.lat +
            "," +
            e.long;
        }
      });
      // 📍จุดส่ง1
      // ชื่อผู้ติดต่อ: วาฮิด
      // เบอร์โทรผู้ติดต่อ: 028887822
      // ชื่อสถานที่: คลังสินค้า
      // ประเภทสถานที่: บริษัทห้างร้าน
      // หมายเหตุ: ทดสอบ
      // พิกัด: https://maps.app.goo.gl/jq5286WA8SUKVHHg6

      setClipboardInfo(text);
      setClipboardInfoCarrier(textCarrier);
      setClipboardLocation(textLoc);
    }
  }, [workDetail, acceptWork]);
  const getWorkDetail = () => {
    let body = {
      work_id: id,
    };
    api
      .getWorkDetail(body)
      .then(async (response) => {
        let data = response.data.results;
        await getProject(data?.shipper_id);
        await getDetail(data?.profile_receipt_name_id);
        console.log(response.data.results);
        let bread = [
          {
            label: "ข้อมูลงาน",
            path: null,
          },
          {
            label: response.data.results.work_code,
            path: null,
          },
          {
            label: "รายละเอียดงาน",
            path: null,
          },
        ];
        // console.log(bread);
        response.data.results.truck_type.image =
          helper.pathImage() + response.data.results.truck_type.image;
        setWorkDetail(response.data.results);
        data.workTrackings = helper.sortArray(data.workTrackings, true);
        // data.workTrackings.reverse();
        data.workPaymentShipperStatus = data.workPaymentShipperStatus.reverse();
        data.workPaymentCarrierStatus = data.workPaymentCarrierStatus.reverse();
        let loc = await helper.workDetailProduct(
          response.data.results.workLocations
        );
        // console.log("loc", loc);
        let document_return_province = "";
        let document_return_subdistrict = "";
        let document_return_district = "";
        if (data.document_return_province) {
          document_return_province = province.filter(
            (e) => e.name === data.document_return_province
          );
        }
        if (data.document_return_subdistrict) {
          document_return_subdistrict = subDistrict.filter(
            (e) => e.name === data.document_return_subdistrict
          );
        }
        if (data.document_return_district) {
          document_return_district = district.filter(
            (e) =>
              e.name ===
              (document_return_province.length > 0 &&
              document_return_province[0].id === 10
                ? data.document_return_district
                : data.document_return_district)
          );
        }
        // console.log(
        //   document_return_province,
        //   document_return_subdistrict,
        //   document_return_district
        // );
        setService({
          has_document_return: data.has_document_return,
          document_return_code: data.document_return_code ?? "",
          document_return_address: data.document_return_address ?? "",
          document_return_district:
            document_return_district.length > 0
              ? document_return_district[0].id
              : "",
          document_return_subdistrict:
            document_return_subdistrict.length > 0
              ? document_return_subdistrict[0].id
              : "",
          document_return_province:
            document_return_province.length > 0
              ? document_return_province[0].id
              : "",
          document_return_postal_code: data.document_return_postal_code ?? "",
          document_return_contact_name: data.document_return_contact_name ?? "",
          document_return_contact_phone:
            data.document_return_contact_phone ?? "",
          has_labor: data.has_labor,
          labor_load_amount: data.labor_load_amount ?? 0,
          labor_unload_amount: data.labor_unload_amount ?? 0,
          has_driver_assist_load_unload: data.has_driver_assist_load_unload,
        });
        setLocation(loc);
        setDatetime({
          date: {
            startDate: data.load_date_start,
            endDate: data.load_date_end,
          },
          time: {
            time_start: data.load_time_start.slice(0, 5),
            time_end: data.load_time_end.slice(0, 5),
          },
        });
        setBreadcrumbs(bread);
        setDistanceTime({
          // allInterval: data.total_duration,
          allDistance: data.total_distance,
          orderDistance: data.total_distance,
          // orderInterval: helper.secToText(data.total_duration),
        });
        setDuration(data.total_duration);
        const price_difference =
          Math.ceil(
            (data.shipper_freight_before_tax -
              data.carrier_freight_before_tax) *
              100
          ) / 100;
        const price_difference_percentage =
          Math.ceil(
            (price_difference / data.shipper_freight_before_tax) * 100 * 100
          ) / 100;
        setPrice({
          work_status: data?.work_status_id,
          shipper: {
            shipper_document_return_fee: data.shipper_document_return_fee, // ค่าเอกสารนำกลับ
            shipper_labor_load_fee: data.shipper_labor_load_fee, // ค่าพนักงานขึ้นสินค้า
            shipper_labor_unload_fee: data.shipper_labor_unload_fee, // ค่าพนักงานลงสินค้า
            shipper_add_drop_point_fee: data.shipper_add_drop_point_fee, // ค่าเพิ่มจุดส่ง
            shipper_transport_fee: data.shipper_transport_fee, // ค่าจัดส่ง
            shipper_freight_before_tax: data.shipper_freight_before_tax, // รวมราคาเรียกเก็บลูกค้าก่อนหักภาษี
            shipper_freight_tax: data.shipper_freight_tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาเรียกเก็บลูกค้าก่อนหักภาษี
            shipper_freight_net: data.shipper_freight_net, // ค่าขนส่งสุทธิที่ต้องรับชำระลูกค้า (กรณีชำระเต็มจำนวน)
            shipper_has_deposit: data.shipper_has_deposit ? 1 : 0, // มีเงินมัดจำ
            shipper_deposit_amount: data.shipper_deposit_amount, // จำนวนเงินมัดจำ
            commission: data.commission, //ค่านายหน้า
            is_general: data.shipper_channel_price_type === 0 ? true : false,
            shipper_channel_price_type: data.shipper_channel_price_type,
            payment_qrcode: data.payment_qrcode,
            work_code: data.work_code,
          },
          carrier: {
            carrier_freight: data.carrier_freight, // ค่าจ้างผู้ขนส่ง
            carrier_saas_fee: data.carrier_saas_fee, // ค่าธรรมเนียมระบบ
            carrier_freight_before_tax: data.carrier_freight_before_tax, // รวมราคาจ่ายผู้ขนส่งก่อนหักภาษี
            carrier_freight_tax: data.carrier_freight_tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาจ่ายผู้ขนส่งก่อนหักภาษี
            carrier_penalty: data.carrier_penalty, // ค่าปรับกรณีทำผิดข้อตกลง
            carrier_insurance_per_trip: data.carrier_insurance_per_trip, // ค่าประกันภัยสินค้ารายเที่ยว
            carrier_transfer_fee: data.carrier_transfer_fee, // ค่าธรรมเนียมการโอนเงิน
            carrier_freight_net: data.carrier_freight_net, // ค่าขนส่งสุทธิที่ต้องชำระผู้ขนส่ง (กรณีชำระเต็มจำนวน)
            is_general: data.carrier_channel_price_type === 0 ? true : false,
            carrier_channel_price_type: data.carrier_channel_price_type,
          },
          difference: {
            price_difference: price_difference,
            price_difference_percentage: price_difference_percentage,
          },
        });
        setDeposit({
          has_deposit: data.shipper_has_deposit ? 1 : 0,
          deposit_amount: data.shipper_deposit_amount,
        });
        setRate({
          shipper: {
            score: data.shipper_score ? parseFloat(data.shipper_score) : null,
            remark: data.shipper_comment ?? "",
          },
          carrier: {
            score: data.carrier_score ? parseFloat(data.carrier_score) : null,
            remark: data.carrier_comment ?? "",
          },
        });
        setAdminNote(data.workNoteAdmins.reverse());
        setLoading(false);
        setRefresh(false);
      })
      .catch((e) => {
        setLoading(false);
        setRefresh(false);

        console.log(e);
      });
  };
  const getGeneralPrice = async (type = null) => {
    let origin_province = [];
    let origin_district = [];
    let origin_subdistrict = [];
    let destination_province = [];
    let destination_district = [];
    let destination_subdistrict = [];
    location.forEach((ele, index) => {
      if (ele.type === 0) {
        origin_province.push(ele.province);
        origin_district.push(ele.district);
        origin_subdistrict.push(ele.subdistrict);
      } else {
        destination_province.push(ele.province);
        destination_district.push(ele.district);
        destination_subdistrict.push(ele.subdistrict);
      }
    });
    let body = {
      shipper_id: workDetail?.shipper_id,
      distance: workDetail?.total_distance,
      truck_type: workDetail?.truck_type.id,
      // truck_type: 1,
      origin_province: origin_province,
      origin_district: origin_district,
      origin_subdistrict: origin_subdistrict,
      destination_province: destination_province,
      destination_district: destination_district,
      destination_subdistrict: destination_subdistrict,
      doc_return: workDetail?.has_document_return,
      load_amount: parseInt(workDetail?.labor_load_amount),
      unload_amount: parseInt(workDetail?.labor_unload_amount),
      service_id: workDetail?.service_id,
      profile_type: receipt?.profile_type ?? 0,
    };
    // console.log(body);
    await api
      .getGeneralPrice(body)
      .then((response) => {
        // console.log("getGeneralPrice, ", response);
        let data = response.data.results.shipper;
        let dataCarrier = response.data.results.carrier;
        let dataDifference = response.data.results.difference;
        let gp = {
          shipper: {
            shipper_document_return_fee: data.doc_fee, // ค่าเอกสารนำกลับ
            shipper_labor_load_fee: data.labor_load_fee, // ค่าพนักงานขึ้นสินค้า
            shipper_labor_unload_fee: data.labor_unload_fee, // ค่าพนักงานลงสินค้า
            shipper_add_drop_point_fee: data.drop_point_fee, // ค่าเพิ่มจุดส่ง
            shipper_transport_fee: data.normal_price, // ค่าจัดส่ง
            shipper_freight_before_tax: data.price_before_tax, // รวมราคาเรียกเก็บลูกค้าก่อนหักภาษี
            shipper_freight_tax: data.tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาเรียกเก็บลูกค้าก่อนหักภาษี
            shipper_freight_net: data.total_price, // ค่าขนส่งสุทธิที่ต้องรับชำระลูกค้า (กรณีชำระเต็มจำนวน)
            shipper_has_deposit: false, // มีเงินมัดจำ
            shipper_deposit_amount: "0", // จำนวนเงินมัดจำ
            commission: "0", //ค่านายหน้า
            is_general: true,
            shipper_channel_price_type: 0,
          },
          carrier: {
            carrier_freight: dataCarrier.price_before_tax, // ค่าจ้างผู้ขนส่ง
            carrier_saas_fee: dataCarrier.saas, // ค่าธรรมเนียมระบบ
            carrier_freight_before_tax: dataCarrier.price_before_tax, // รวมราคาจ่ายผู้ขนส่งก่อนหักภาษี
            carrier_freight_tax: dataCarrier.tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาจ่ายผู้ขนส่งก่อนหักภาษี
            carrier_penalty: dataCarrier.carrier_penalty, // ค่าปรับกรณีทำผิดข้อตกลง
            carrier_insurance_per_trip:
              workDetail.carrier_insurance_per_trip ?? 0, // ค่าประกันภัยสินค้ารายเที่ยว
            carrier_transfer_fee: 0, // ค่าธรรมเนียมการโอนเงิน
            carrier_freight_net:
              dataCarrier.total_price -
              (workDetail.carrier_insurance_per_trip ?? 0), // ค่าขนส่งสุทธิที่ต้องชำระผู้ขนส่ง (กรณีชำระเต็มจำนวน)
            is_general: true,
            carrier_channel_price_type: 0,
          },
          difference: {
            price_difference: dataDifference.price_difference,
            price_difference_percentage:
              dataDifference.price_difference_percentage,
          },
        };
        setGeneralPrice(gp);
        if (type) {
          console.log("type", type);
          setPrice({ ...price, [type]: gp[type] });
          // if (type === "carrier") setPrice({ ...price, [type]: gp[type] });
          // else setPrice(gp);
        }
      })
      .catch((error) => {
        console.log("error general price ", error);
      });
  };
  const getOnDemandPrice = async (type = null) => {
    let origin_province = [];
    let origin_district = [];
    let origin_subdistrict = [];
    let destination_province = [];
    let destination_district = [];
    let destination_subdistrict = [];
    location.forEach((ele, index) => {
      if (ele.type === 0) {
        origin_province.push(ele.province);
        origin_district.push(ele.district);
        origin_subdistrict.push(ele.subdistrict);
      } else {
        destination_province.push(ele.province);
        destination_district.push(ele.district);
        destination_subdistrict.push(ele.subdistrict);
      }
    });
    let body = {
      shipper_id: workDetail?.shipper_id,
      distance: workDetail?.total_distance,
      truck_type: workDetail?.truck_type.id,
      // truck_type: 1,
      origin_province: origin_province,
      origin_district: origin_district,
      origin_subdistrict: origin_subdistrict,
      destination_province: destination_province,
      destination_district: destination_district,
      destination_subdistrict: destination_subdistrict,
      doc_return: workDetail?.has_document_return,
      load_amount: parseInt(workDetail?.labor_load_amount),
      unload_amount: parseInt(workDetail?.labor_unload_amount),
      service_id: workDetail?.service_id,
      profile_type: receipt?.profile_type ?? 0,
    };
    // console.log(body);
    api
      .getGeneralPrice({ ...body, price_type: "on-demand" })
      .then((response) => {
        console.log("getGeneralPrice, ", response);
        let data = response.data.results.shipper;
        let dataCarrier = response.data.results.carrier;
        let dataDifference = response.data.results.difference;
        let dp = {
          shipper: {
            shipper_document_return_fee: data.doc_fee, // ค่าเอกสารนำกลับ
            shipper_labor_load_fee: data.labor_load_fee, // ค่าพนักงานขึ้นสินค้า
            shipper_labor_unload_fee: data.labor_unload_fee, // ค่าพนักงานลงสินค้า
            shipper_add_drop_point_fee: data.drop_point_fee, // ค่าเพิ่มจุดส่ง
            shipper_transport_fee: data.normal_price, // ค่าจัดส่ง
            shipper_freight_before_tax: data.price_before_tax, // รวมราคาเรียกเก็บลูกค้าก่อนหักภาษี
            shipper_freight_tax: data.tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาเรียกเก็บลูกค้าก่อนหักภาษี
            shipper_freight_net: data.total_price, // ค่าขนส่งสุทธิที่ต้องรับชำระลูกค้า (กรณีชำระเต็มจำนวน)
            shipper_has_deposit: false, // มีเงินมัดจำ
            shipper_deposit_amount: "0", // จำนวนเงินมัดจำ
            commission: "0", //ค่านายหน้า
            is_general: false,
            shipper_channel_price_type: 4,
          },
          carrier: {
            carrier_freight: dataCarrier.price_before_tax, // ค่าจ้างผู้ขนส่ง
            carrier_saas_fee: dataCarrier.saas, // ค่าธรรมเนียมระบบ
            carrier_freight_before_tax: dataCarrier.price_before_tax, // รวมราคาจ่ายผู้ขนส่งก่อนหักภาษี
            carrier_freight_tax: dataCarrier.tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาจ่ายผู้ขนส่งก่อนหักภาษี
            carrier_penalty: 0, // ค่าปรับกรณีทำผิดข้อตกลง
            carrier_insurance_per_trip: 0, // ค่าประกันภัยสินค้ารายเที่ยว
            carrier_transfer_fee: 0, // ค่าธรรมเนียมการโอนเงิน
            carrier_freight_net: dataCarrier.total_price, // ค่าขนส่งสุทธิที่ต้องชำระผู้ขนส่ง (กรณีชำระเต็มจำนวน)
            is_general: false,
            carrier_channel_price_type: 5,
          },
          difference: {
            price_difference: dataDifference.price_difference,
            price_difference_percentage:
              dataDifference.price_difference_percentage,
          },
        };
        setOnDemandPrice(dp);
        if (type) {
          console.log("type", type);
          setPrice({ ...price, [type]: dp[type] });
          // if (type === "carrier") setPrice({ ...price, [type]: gp[type] });
          // else setPrice(gp);
        }
      })
      .catch((error) => {
        console.log("error general price ", error);
        if (error.response.data.code === 4406) {
          Swal.fire({
            // timer: 2000,
            title: "error: " + error.response.data.code,
            text: error.response.data.description,
            icon: "error",
            // showConfirmButton: false,
          });
        }
      });
  };
  const setMarker = async () => {
    let has_marker = false;
    let list = [];
    for (const { ele, index } of location.map((ele, index) => ({
      ele,
      index,
    }))) {
      if (ele.lat !== "" && ele.long !== "") {
        // console.log(ele.lat, ele.long);
        const dot = await new window.longdo.Marker(
          { lat: ele.lat, lon: ele.long },
          {
            draggable: false,
            clickable: true,
            weight: window.longdo.OverlayWeight.Top,
            icon: {
              html:
                '<div class=""><img src="' +
                (ele.type === 0
                  ? require("../../assets/image/work/pickup_pin.png")
                  : require("../../assets/image/work/send_pin.png")) +
                '" style="height: 30px"/></div>',
            },
          }
        );
        list.push(dot);
      }
    }
    setCall(list);
    let mark_status = await HelperMap.getMarkerFromStatus(
      workDetail?.workTrackings
    );
    setMarkerStatus(mark_status);
    // console.log("mark_status", mark_status);
  };
  const getDriver = async () => {
    await api
      .getDriverLoc({
        work_id: workDetail?.id,
        client_id: socketId,
      })
      .then((response) => {
        console.log("getDriverLoc", response.data.results);
        setDriverLoc({
          lat: response.data.results.lat,
          long: response.data.results.long,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getDetail = async (id) => {
    let rec = await getProfileReceiptName(id);
    // console.log("getDetail", rec);
    setReceipt(rec.data.data.results);
  };
  const editWork = async (type) => {
    console.log("editWork");
    // console.log(price);
    // console.log(receipt);
    // console.log(workDetail.ref_customer_code);
    // console.log(workDetail, "edit");
    console.log("price,", price);
    let body;
    if (type === "all") {
      body = {
        ref_customer_code_1: workDetail.ref_customer_code_1,
        ref_customer_code_2: workDetail.ref_customer_code_2,
        ref_customer_code_3: workDetail.ref_customer_code_3,
        ref_customer_code_4: workDetail.ref_customer_code_4,
        ref_customer_code_5: workDetail.ref_customer_code_5,
        ref_customer_code_6: workDetail.ref_customer_code_6,
        ref_customer_code_7: workDetail.ref_customer_code_7,
        ref_customer_code_8: workDetail.ref_customer_code_8,
        ref_customer_code_9: workDetail.ref_customer_code_9,
        ref_customer_code_10: workDetail.ref_customer_code_10,
        project_id: workDetail.project_id,
        shipper_channel_price_type: price.shipper.shipper_channel_price_type,
        carrier_channel_price_type: price.carrier.carrier_channel_price_type,
        shipper_freight_net: price.shipper.shipper_freight_net,
        shipper_document_return_fee: price.shipper.shipper_document_return_fee,
        shipper_labor_load_fee: price.shipper.shipper_labor_load_fee,
        shipper_labor_unload_fee: price.shipper.shipper_labor_unload_fee,
        shipper_add_drop_point_fee: price.shipper.shipper_add_drop_point_fee,
        shipper_transport_fee: price.shipper.shipper_transport_fee,
        shipper_freight_before_tax: price.shipper.shipper_freight_before_tax,
        shipper_freight_tax: price.shipper.shipper_freight_tax,
        shipper_has_deposit: price.shipper.shipper_has_deposit,
        has_deposit:
          deposit?.has_deposit && deposit?.has_deposit === 1 ? true : false,
        shipper_deposit_amount: deposit?.has_deposit
          ? deposit.deposit_amount
          : 0,
        commission: price.difference.price_difference,
        carrier_freight: price.carrier.carrier_freight,
        carrier_saas_fee: price.carrier.carrier_saas_fee ?? 0,
        carrier_freight_before_tax: price.carrier.carrier_freight_before_tax,
        carrier_freight_tax: price.carrier.carrier_freight_tax,
        carrier_penalty:
          price.carrier.carrier_penalty === ""
            ? 0
            : price.carrier.carrier_penalty,
        carrier_insurance_per_trip:
          price.carrier.carrier_insurance_per_trip ?? 0,
        carrier_transfer_fee: price.carrier.carrier_transfer_fee ?? 0,
        carrier_freight_net: price.carrier.carrier_freight_net,
        receipt_id_number: receipt.id,
        truck_type_id: workDetail?.truck_type.id,
        type: type,
        edit_price: editPrice,
      };
    }
    if (type === "detail") {
      body = {
        ref_customer_code_1: workDetail.ref_customer_code_1,
        ref_customer_code_2: workDetail.ref_customer_code_2,
        ref_customer_code_3: workDetail.ref_customer_code_3,
        ref_customer_code_4: workDetail.ref_customer_code_4,
        ref_customer_code_5: workDetail.ref_customer_code_5,
        ref_customer_code_6: workDetail.ref_customer_code_6,
        ref_customer_code_7: workDetail.ref_customer_code_7,
        ref_customer_code_8: workDetail.ref_customer_code_8,
        ref_customer_code_9: workDetail.ref_customer_code_9,
        ref_customer_code_10: workDetail.ref_customer_code_10,
        project_id: workDetail.project_id,
        type: type,
        edit_price: editPrice,
      };
    }

    // console.log("body,", body);
    // // setLoading(false);

    // return;
    setShowModalEditDetail(false);
    setShowModal(false);

    await api
      .editWork([workDetail.id], body)
      .then((response) => {
        // console.log("edit", response);
        // setLoading(false);
        Swal.fire({
          title: "แก้ไขเสร็จสิ้น",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
          willClose: () => {
            if (is_different_months) {
              if (haveFixedRole) {
                saveNote();
              }
            } else if (!haveFixedRole && check_date_no_role) {
              saveNote();
            }
            getWorkDetail();
          },
        });
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          title: "เกิดช้อผิดพลาด",
          text: e.response?.data?.description ?? "กรุณาลองใหม่ภายหลัง",
          icon: "error",
        });
        setLoading(false);
      });
  };

  const getProject = async (shipper_id) => {
    await api
      .project({
        profile_id: shipper_id,
        page: 1,
        active: true,
        per_page: 9999,
      })
      .then((res) => {
        console.log("response", res);
        let option = res.data.data.map((ele, index) => {
          return { ...ele, item: ele.name, value: ele.id };
        });
        setProjectList(option);
      })
      .catch((error) => {
        setProjectList([]);
      });
  };
  // const copyToClipboard = () => {
  //   const baseUrl = window?.location?.origin;
  //   const url = `${baseUrl}/public/tracking/${workDetail?.id_secret}`;

  //   if (navigator.clipboard) {
  //     navigator.clipboard
  //       .writeText(url)
  //       .then(() => {
  //         Swal.fire({
  //           title: "คัดลอกสำเร็จ",
  //           icon: "success",
  //         });
  //       })
  //       .catch((err) => {
  //         console.error("Unable to copy text to clipboard", err);
  //       });
  //   } else {
  //     console.error("Clipboard API not supported");
  //   }
  // };

  const saveNote = async () => {
    const data = adminNoteEdit;
    console.log("saveNote", data);
    let formData = new FormData();
    formData.append("note_type", data.note_type);
    if (data.image && data.image.file)
      formData.append("image", data.image?.file);
    formData.append("detail", data.detail);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    await api
      .adminNote(workDetail?.id, formData)
      .then((response) => {
        setShowAdminNoteEdit(false);
      })
      .catch((error) => {
        if (error.response?.data)
          Swal.fire({
            timer: 2000,
            title: "error: " + error.response.data.code,
            text: error.response.data.description ?? "",
            icon: "error",
            showConfirmButton: false,
          });
        else
          Swal.fire({
            timer: 2000,
            title: "เกิดข้อผิดพลาด",
            text: "กรุณาลองใหม่ภายหลัง",
            icon: "error",
            showConfirmButton: false,
          });
      });
  };
  return (
    <div className="post-work-page">
      {loading ? <LoadingBackground /> : null}
      <div className="post-work-body">
        <div className="post-work-body-flex">
          <div className="sub-menu-post-work">
            <SubMenu menus={menus} />
          </div>
          <div className="sub-menu-post-work-breadcrumbs">
            <SubMenu breadcrumbs={breadcrumbs} />
          </div>
        </div>
        <div className="post-work-body-flex">
          <div className="post-work-box">
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: 20, fontWeight: 500 }}>
                {workDetail?.work_code}
              </div>
              <div style={{ fontStyle: "italic", fontSize: 14, opacity: 0.4 }}>
                {"วันที่สร้างรายการ " +
                  helper.momentDateTime(workDetail?.createdAt, "short", false)}
              </div>
              <div style={{ fontStyle: "italic", fontSize: 14, opacity: 0.4 }}>
                {"วันที่แก้ไขข้อมูลล่าสุด " +
                  helper.momentDateTime(workDetail?.updatedAt, "short", false)}
              </div>
            </div>
            <div>
              <InputDisable
                label={"สถานะใช้งาน"}
                value={workDetail?.active ? "ใช้งาน" : "ไม่ใช้งาน"}
                opacity
              />
            </div>
            <div>
              <InputDisable
                label={"รหัสผู้ใช้งาน*"}
                value={workDetail?.shipper?.user_info?.user_code ?? ""}
                opacity
              />
            </div>
            <div>
              <InputDisable
                label={"ชื่อโปรไฟล์"}
                value={workDetail?.shipper?.company_name ?? ""}
                opacity
              />
            </div>
            <div>
              {workDetail ? (
                <FormControl
                  variant="standard"
                  autoFocus
                  fullWidth
                  margin="none"
                >
                  <InputLabel color={"primary"}>โครงการ*</InputLabel>
                  <Select
                    value={workDetail?.project_id}
                    onChange={(val) => {
                      console.log(val, "select");
                      let code = projectList.filter(
                        (ele) => ele.id === val.target.value
                      )[0];
                      setWorkDetail({
                        ...workDetail,
                        project_id: val.target.value,
                        project: {
                          ...workDetail.project,
                          name: code.name,
                          project_code: code.project_code,
                        },
                      });
                    }}
                    defaultValue={workDetail?.project_id}
                    label="โครงการ"
                  >
                    {projectList.map((ele, index) => {
                      return (
                        <MenuItem key={"project" + ele.id} value={ele.id}>
                          {ele.item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                <InputDisable
                  label={"โครงการ*"}
                  value={workDetail?.project?.name ?? ""}
                  opacity
                />
              )}
            </div>
            <div>
              <InputDisable
                label={"รหัสโครงการ"}
                value={workDetail?.project?.project_code ?? ""}
                opacity
              />
            </div>
            <div>
              <InputDisable
                label={"อัตราน้ำมัน*"}
                value={workDetail?.fuel_rate ?? ""}
                opacity
              />
            </div>
            <div>
              <InputNormal
                label={"CS เลขที่เอกสารส่งมอบสินค้า"}
                value={workDetail?.ref_customer_code_1 ?? ""}
                onChange={(val) => {
                  setWorkDetail({
                    ...workDetail,
                    ref_customer_code_1: val.target.value,
                  });
                }}
              />
            </div>
            <div>
              <InputNormal
                label={"CS วันที่ในเอกสาร (เอกสารนำกลับ)"}
                value={workDetail?.ref_customer_code_2 ?? ""}
                onChange={(val) => {
                  setWorkDetail({
                    ...workDetail,
                    ref_customer_code_2: val.target.value,
                  });
                }}
              />
            </div>
            <div>
              <InputNormal
                label={"CS น้ำหนัก / จำนวนสินค้า"}
                value={workDetail?.ref_customer_code_3 ?? ""}
                onChange={(val) => {
                  setWorkDetail({
                    ...workDetail,
                    ref_customer_code_3: val.target.value,
                  });
                }}
              />
            </div>
            <div>
              <InputNormal
                label={"CS เลขที่ Shipment"}
                value={workDetail?.ref_customer_code_4 ?? ""}
                onChange={(val) => {
                  setWorkDetail({
                    ...workDetail,
                    ref_customer_code_4: val.target.value,
                  });
                }}
              />
            </div>
            <div>
              <InputNormal
                label={"CS ชื่อร้านค้าที่ส่งสินค้า"}
                value={workDetail?.ref_customer_code_5 ?? ""}
                onChange={(val) => {
                  setWorkDetail({
                    ...workDetail,
                    ref_customer_code_5: val.target.value,
                  });
                }}
              />
            </div>
            <div>
              <InputNormal
                label={"OP วันที่ในเอกสาร PO / PR"}
                value={workDetail?.ref_customer_code_6 ?? ""}
                onChange={(val) => {
                  setWorkDetail({
                    ...workDetail,
                    ref_customer_code_6: val.target.value,
                  });
                }}
              />
            </div>
            <div>
              <InputNormal
                label={"OP เลขที่ในเอกสาร PO / PR"}
                value={workDetail?.ref_customer_code_7 ?? ""}
                onChange={(val) => {
                  setWorkDetail({
                    ...workDetail,
                    ref_customer_code_7: val.target.value,
                  });
                }}
              />
            </div>
            <div>
              <InputNormal
                label={"OP เรทราคาน้ำมัน"}
                value={workDetail?.ref_customer_code_8 ?? ""}
                onChange={(val) => {
                  setWorkDetail({
                    ...workDetail,
                    ref_customer_code_8: val.target.value,
                  });
                }}
              />
            </div>
            <div>
              <InputNormal
                label={"OP น้ำหนักที่ลูกค้าแจ้ง"}
                value={workDetail?.ref_customer_code_9 ?? ""}
                onChange={(val) => {
                  setWorkDetail({
                    ...workDetail,
                    ref_customer_code_9: val.target.value,
                  });
                }}
              />
            </div>
            <div>
              <InputNormal
                label={"OP อื่น ๆ"}
                value={workDetail?.ref_customer_code_10 ?? ""}
                onChange={(val) => {
                  setWorkDetail({
                    ...workDetail,
                    ref_customer_code_10: val.target.value,
                  });
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{ height: 50 }}
                color="secondary"
                onClick={() => {
                  // editWork();
                  setShowModalEditDetail(true);
                }}
              >
                บันทึกรายละเอียด
              </Button>
            </div>
          </div>
          <div className="post-work-box">
            <div style={{ display: "flex" }}>
              <div>
                <img
                  src={
                    workDetail?.service_id === 1
                      ? require("../../assets/image/work/ftl.png")
                      : require("../../assets/image/work/ltl.png")
                  }
                  alt=""
                  style={{ width: 30, height: 30, margin: 10 }}
                />
              </div>
              <div>
                <div style={{ fontSize: 16 }}>
                  {workDetail?.service_id === 1
                    ? "บริการเหมาคัน"
                    : "บริการฝากส่ง"}
                </div>
                <div style={{ fontSize: 12 }}>
                  {workDetail?.service_id === 1
                    ? "ส่งของเยอะเหมาทั้งคัน"
                    : "ส่งของชิ้นใหญ่จำนวนน้อย"}
                </div>
              </div>
            </div>
            <Line opacity={1} />
            <SelectLocation value={location} is_detail />
            <Line />
            <SelectTruck
              value={workDetail?.truck_type}
              is_detail={
                workDetail?.driver_id || workDetail?.work_status_id === 15
                  ? true
                  : false
              }
              onChange={(val) => {
                setWorkDetail({
                  ...workDetail,
                  truck_type: val,
                });
                // setValue("truck", val);
                // console.log("watchService before", watchService);
                // let tmp = { ...watchService };
                // tmp.has_labor = false;
                // tmp.labor_load_amount = 0;
                // tmp.labor_unload_amount = 0;
                // setValue("service", tmp);
                // console.log("watchService after", watchService);
              }}
            />
            <Line />
            <SelectProduct
              value={location.length > 0 ? location[0].product : null}
              workType={workDetail?.service_id === 1 ? "ftl" : "stl"}
              is_detail
            />
            <Line />
            {service?.has_document_return ||
            service?.has_labor ||
            service.has_driver_assist_load_unload ? (
              <SelectService
                value={service}
                is_detail
                truck={workDetail?.truck_type}
                service_price={price}
              />
            ) : null}

            <Line />
            <SelectDateTime value={datetime} is_detail />
            <Line />
            {workDetail?.carrier ? (
              <SelectCarrier
                carrier={workDetail?.carrier}
                driver={workDetail?.driver}
                truck={
                  workDetail?.workTrackings?.filter((e) => e.work_status === 4)
                    .length > 0
                    ? workDetail?.workTrackings?.filter(
                        (e) => e.work_status === 4
                      )[0].workTrackingDetail.truck
                    : null
                }
              />
            ) : null}
            <Line />
            <SelectBilling
              value={receipt}
              onChange={(val) => {
                // setValue("billing", val);
                setReceipt(val);
              }}
              profile_id={workDetail?.shipper_id}
            />
            <Line />
            {workDetail?.work_remark && workDetail?.work_remark !== "" ? (
              <div>
                <SelectRemark value={workDetail?.work_remark} is_detail />
                <Line />
              </div>
            ) : null}
            {workDetail?.work_name && workDetail?.work_name !== "" ? (
              <div>
                <SelectPostName value={workDetail?.work_name} is_detail />
                <Line />
              </div>
            ) : null}
            {workDetail?.workFavoriteGroup ? (
              <SelectFavCarrier is_detail={workDetail?.workFavoriteGroup} />
            ) : null}
          </div>
          <div className="post-work-box-map" id="post-work-box-map">
            <div className="map-post-work">
              <MapWithCall
                call={call}
                marker={markerStatus}
                driver={driverLoc}
              />
            </div>
            <div
              style={{
                // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                padding: 20,
                borderRadius: 20,
                textAlign: "center",
                position: "absolute",
                width: "100%",
                overflow: "scroll",
                bottom: 0,
                backgroundColor: "white",
              }}
              id={"work-detail-status"}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowStatus(!showStatus);
                  if (!loadMap) {
                    setLoadmap(true);
                  }
                }}
              >
                <div
                  style={{
                    width: 50,
                    height: 5,
                    backgroundColor: "#8ca3ba",
                    position: "absolute",
                    top: 2.5,
                    transform: "translate(-50%, -50%)",
                    borderRadius: "0px 0px 10px 10px",
                    left: "50%",
                  }}
                ></div>
                <div>ระยะทางประมาณ {distanceTime?.orderDistance ?? 0} กม.</div>
                {/* <div>
                  ระยะเวลาเดินทางประมาณ{" "}
                  {distanceTime?.orderInterval ?? "- ชั่วโมง"}
                </div> */}
                <div>ระยะเวลาเดินทางประมาณ {duration?.replace(/,/g, " ")}</div>
              </div>
              {showStatus ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div></div>
                    <div style={{ opacity: 0.5 }}>ติดตามสถานะ</div>
                    <div style={{ cursor: "pointer" }}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setModalShare(true)}
                      >
                        <ShareIcon />
                      </div>
                    </div>
                  </div>
                  <Line opacity={1} />
                  <WorkStatus data={workDetail?.workTrackings ?? null} />
                </div>
              ) : null}
            </div>
          </div>
          <div className="post-work-box">
            {/* <SelectPrice data={price} deposit={deposit ?? null} /> */}
            <SelectPrice
              data={price}
              hasDeposit={(val) => {
                setDeposit({ ...deposit, has_deposit: val.target.value });
                // setValue("deposit", {
                //   ...watchDeposit,
                //   has_deposit: val.target.value,
                // });
              }}
              depositAmount={(val) => {
                setDeposit({ ...deposit, deposit_amount: val.target.value });

                // setValue("deposit", {
                //   ...watchDeposit,
                //   deposit_amount: val.target.value,
                // });
              }}
              onChange={(val) => {
                setPrice(val);
                getGeneralPrice();
                getOnDemandPrice();
                setEditPrice(true);
              }}
              deposit={deposit}
              destination={location.filter((e) => e.type === 1).length}
              doc_return={service.has_document_return}
              labor_load_amount={
                service.has_labor && service.labor_load_amount > 0
                  ? service.labor_load_amount
                  : 0
              }
              labor_unload_amount={
                service.has_labor && service.labor_unload_amount > 0
                  ? service.labor_unload_amount
                  : 0
              }
              saas={workDetail?.truck_type?.saas ?? 0}
              generalPrice={generalPrice}
              is_detail
              profile_type={receipt?.profile_type ?? 0}
              setGeneralPrice={(type) => {
                getGeneralPrice(type);
              }}
              onDemandPrice={onDemandPrice}
              setOnDemandPrice={(type) => {
                getOnDemandPrice(type);
              }}
            />
            <div>
              <div style={{ flex: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{ height: 50 }}
                  color="secondary"
                  disabled={
                    accepted_work
                      ? !haveFixedRole
                        ? !check_date_no_role
                          ? true
                          : false
                        : false
                      : false
                  }
                  onClick={() => {
                    // editWork();
                    if (!accepted_work) {
                      setShowModal(true);
                    } else if (is_different_months) {
                      if (haveFixedRole && workDetail?.active === true) {
                        setShowAdminNoteEdit(true);
                      } else if (!haveFixedRole) {
                        if (check_date_no_role && workDetail?.active === true) {
                          setShowAdminNoteEdit(true);
                        }
                      } else {
                        setShowModal(true);
                      }
                    } else {
                      setShowModal(true);
                    }
                  }}
                >
                  บันทึก
                </Button>
              </div>
              <div style={{ flex: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outline"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    color: "#8ca3ba",
                    borderColor: "#8ca3ba",
                    height: 50,
                  }}
                  color="secondary"
                  onClick={() => {
                    // redirect("/post-work/" + workDetail.id);
                    console.log(window.location.host);
                    window.location.href =
                      window.location.protocol +
                      "//" +
                      window.location.host +
                      "/post-work/" +
                      workDetail.id;
                  }}
                >
                  <div>
                    <div>ประกาศซ้ำ</div>
                    <div style={{ fontSize: 8 }}>
                      {
                        "(ระบบจะกรอกข้อมูลเดิมอัตโนมัติยกเว้นช่วงวันและเวลาที่สะดวกให้เข้ารับสินค้า)"
                      }
                    </div>
                  </div>
                </Button>
              </div>
              {/* <div style={{ flex: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outline"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    color: "#8ca3ba",
                    borderColor: "#8ca3ba",
                    height: 50,
                  }}
                  color="secondary"
                  onClick={() => {}}
                >
                  ประกาศซ้ำ
                </Button>
              </div> */}
            </div>
          </div>
          <div className="post-work-box">
            <PaymentStatus
              data={workDetail?.workPaymentShipperStatus}
              type={"shipper"}
            />
          </div>
          <div className="post-work-box">
            <PaymentStatus
              data={workDetail?.workPaymentCarrierStatus}
              type={"carrier"}
            />
          </div>
          <div className="post-work-box">
            <AdminNote
              refresh={() => {
                setRefresh(true);
              }}
              work_id={workDetail?.id}
              note={adminNote}
            />
          </div>
          <div className="post-work-box">
            {workDetail ? (
              <RateUser
                type="carrier"
                user_id={workDetail?.carrier_id}
                work_id={workDetail?.id}
                rate={rate.carrier}
                refresh={() => {
                  setRefresh(true);
                }}
                is_success={
                  // workDetail?.work_status_id === 15
                  true
                }
              />
            ) : null}
            {workDetail ? (
              <RateUser
                type="shipper"
                user_id={workDetail?.shipper_id}
                work_id={workDetail?.id}
                rate={rate.shipper}
                refresh={() => {
                  setRefresh(true);
                }}
                is_success={
                  true
                  // workDetail?.work_status_id === 15
                }
              />
            ) : null}
          </div>
        </div>
      </div>
      <ModalWork open={showModal}>
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ textAlign: "center" }}>
            <h3 style={{ color: "#121f43" }}>บันทึก</h3>
            <label>ยืนยันการบันทึก?</label>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="outline"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{
                  borderStyle: "solid",
                  borderWidth: 1,
                  color: "#8ca3ba",
                  borderColor: "#8ca3ba",
                  height: 50,
                }}
                color="secondary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                ยกเลิก
              </Button>
            </div>
            <div style={{ flex: 1, marginLeft: 20 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{ height: 50 }}
                color="secondary"
                onClick={() => {
                  setLoading(true);
                  editWork("all");
                }}
              >
                ยืนยัน
              </Button>
            </div>
          </div>
        </div>
      </ModalWork>
      <ModalWork open={showModalEditDetail}>
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ textAlign: "center" }}>
            <h3 style={{ color: "#121f43" }}>บันทึก</h3>
            <label>ยืนยันการบันทึกรายละเอียด?</label>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="outline"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{
                  borderStyle: "solid",
                  borderWidth: 1,
                  color: "#8ca3ba",
                  borderColor: "#8ca3ba",
                  height: 50,
                }}
                color="secondary"
                onClick={() => {
                  setShowModalEditDetail(false);
                }}
              >
                ยกเลิก
              </Button>
            </div>
            <div style={{ flex: 1, marginLeft: 20 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{ height: 50 }}
                color="secondary"
                onClick={() => {
                  setLoading(true);
                  editWork("detail");
                }}
              >
                ยืนยัน
              </Button>
            </div>
          </div>
        </div>
      </ModalWork>
      <ModalWork
        open={modalShare}
        enableClose
        close={() => {
          setModalShare(false);
        }}
      >
        <div
          style={{
            padding: `0 20px 20px 20px`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CopyToClipboard
            text={clipboardInfo}
            onCopy={() => {
              Swal.fire({
                title: "คัดลอกสำเร็จ",
                icon: "success",
              });
              setModalShare(false);
            }}
          >
            <div style={{ paddingHorizontal: 10 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{
                  borderRadius: "10px",
                  marginTop: 20,
                  backgroundColor: "#EC1F26",
                }}
                sx={{ height: 50 }}
                color="secondary"
              >
                ลูกค้า
              </Button>
            </div>
          </CopyToClipboard>
          <CopyToClipboard
            text={clipboardInfoCarrier}
            onCopy={() => {
              Swal.fire({
                title: "คัดลอกสำเร็จ",
                icon: "success",
              });
              setModalShare(false);
            }}
          >
            <div style={{ paddingHorizontal: 10 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{ height: 50 }}
                color="secondary"
              >
                ผู้ขนส่ง
              </Button>
            </div>
          </CopyToClipboard>
          <CopyToClipboard
            text={clipboardLocation}
            onCopy={() => {
              Swal.fire({
                title: "คัดลอกสำเร็จ",
                icon: "success",
              });
              setModalShare(false);
            }}
          >
            <div style={{ paddingHorizontal: 10 }}>
              <Button
                type="submit"
                fullWidth
                variant="outline"
                size="large"
                // style={{
                //   borderRadius: "10px",
                //   marginTop: 20,
                //   backgroundColor: "#EC1F26",
                // }}
                // sx={{ height: 50 }}
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{
                  borderStyle: "solid",
                  borderWidth: 1,
                  color: "#8ca3ba",
                  borderColor: "#8ca3ba",
                  height: 50,
                }}
                color="secondary"
              >
                ข้อมูลจุดรับส่ง
              </Button>
            </div>
          </CopyToClipboard>
        </div>
      </ModalWork>

      <ModalWork
        open={showAdminNoteEdit}
        close={() => {
          setShowAdminNoteEdit(false);
        }}
        enableClose
      >
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ textAlign: "center" }}>เพิ่มบันทึก</div>
          <Line />
          <InputSelect
            value={adminNoteEdit.note_type}
            label={"ประเภทบันทึก"}
            options={noteTypeOptions}
            error={adminNoteEdit.note_type === null ? true : false}
            onChange={(event) =>
              setAdminNoteEdit({
                ...adminNoteEdit,
                note_type: event.target.value,
              })
            }
          />
          <TextField
            fullWidth
            id="standard-multiline-static"
            label="รายละเอียด"
            value={adminNoteEdit?.detail}
            multiline
            onChange={(event) =>
              setAdminNoteEdit({
                ...adminNoteEdit,
                detail: event.target.value,
              })
            }
            rows={4}
            variant="standard"
            error={adminNoteEdit.detail === "" ? true : false}
          />

          <div style={{ marginTop: 10 }}>
            <InputUpload
              value={adminNoteEdit?.image}
              label={"รูปประกอบ"}
              onChange={(event) =>
                setAdminNoteEdit({
                  ...adminNoteEdit,
                  image: event.target.value,
                })
              }
            />
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            style={{ borderRadius: "10px", marginTop: 20 }}
            sx={{ height: 50 }}
            color="secondary"
            onClick={() => {
              setLoading(true);
              editWork("all");
              setShowAdminNoteEdit(false);
            }}
          >
            <div>บันทึก</div>
          </Button>
        </div>
      </ModalWork>
      {/* <EditButton
        onClick={() => {
          console.log("edit");
          setShowModal(true);
        }}
      /> */}
    </div>
  );
}
