import { ArrowForwardIosOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalSelectTruck from "./modalSelectTruck";
import ModalSelectProduct from "./modalSelectProduct";
import Swal from "sweetalert2";
import ModalSelectService from "./modalSelectService";

const SelectService = (props) => {
  const [show, setShow] = useState(false);
  const {
    value,
    onChange = () => {},
    truck,
    is_detail = false,
    service_price = null,
    is_public = false,
    close_price = false,
  } = props;

  const clickShow = () => {
    setShow(true);
  };
  const clickClose = () => {
    setShow(false);
  };
  // useEffect(() => {
  //   console.log("truck", truck);
  // }, [truck]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          // alignItems: "center",
          textAlign: "center",
          padding: "10px 0px 10px 0px",
          cursor: "pointer",
        }}
        onClick={() => {
          clickShow();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 10px 5px 10px",
          }}
        >
          <img
            src={
              value && value.image
                ? value.image
                : require("../../assets/image/work/service.png")
            }
            height={40}
            alt={"service"}
          />
        </div>
        <div style={{ flex: 1, textAlignLast: "start" }}>
          <div
            style={{
              fontWeight: 500,
              opacity:
                value.has_document_return ||
                value.has_labor ||
                value.has_driver_assist_load_unload
                  ? 1
                  : 0.5,
            }}
          >
            {"บริการเสริม"}
          </div>
          <div
            style={{
              fontSize: 12,
              opacity:
                value.has_document_return ||
                value.has_labor ||
                value.has_driver_assist_load_unload
                  ? 1
                  : 0.3,
            }}
          >
            {value.has_document_return ||
            value.has_labor ||
            value.has_driver_assist_load_unload ? (
              <div>
                {value.has_document_return ? (
                  <div>ต้องการส่งเอกสารนำกลับ</div>
                ) : null}
                {value.has_labor && value.labor_load_amount > 0 ? (
                  <div>
                    ต้องการคนงานยกสินค้าขึ้น {value.labor_load_amount} คน
                  </div>
                ) : null}
                {value.has_labor && value.labor_unload_amount > 0 ? (
                  <div>
                    ต้องการคนงานยกสินค้าลง {value.labor_unload_amount} คน
                  </div>
                ) : null}
                {value.has_driver_assist_load_unload ? (
                  <div>ต้องการคนขับช่วยยกสินค้า</div>
                ) : null}
              </div>
            ) : (
              "ต้องการส่งเอกสารนำกลับ ต้องการคนงานยกสินค้าขึ้นลง ต้องการคนขับช่วยยกสินค้า"
            )}
          </div>
        </div>
        <div style={{ alignSelf: "center" }}>
          <ArrowForwardIosIcon sx={{ color: "#121f43", opacity: 0.3 }} />
        </div>
      </div>
      <ModalSelectService
        value={value}
        onChange={onChange}
        open={show}
        truck={truck}
        close={() => {
          Swal.fire({
            title: "ยกเลิกการเปลี่ยนแปลง ?",
            showDenyButton: true,
            // showCancelButton: true,
            denyButtonText: "ยกเลิก",
            confirmButtonText: `ดำเนินการต่อ`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              // onChange([
              //   {
              //     product_id: "",
              //     product_name: "",
              //     product_size: "",
              //     product_width: "",
              //     product_length: "",
              //     product_height: "",
              //     product_volume: "",
              //     product_weight: "",
              //     product_count_type: "",
              //     product_amount: "",
              //     product_image: [null,null,null,null,null],
              //     // product_image_per_route: [],
              //   },
              // ]);
              setShow(false);
            }
          });
        }}
        confirm={() => {
          setShow(false);
        }}
        is_detail={is_detail}
        is_public={is_public}
        service_price={service_price}
        close_price={close_price}
      />
    </div>
  );
};
export default SelectService;
